$(document).ready(function() {
  // 判斷手機改超連結
  if (/Android|webOS|Nexus|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    // Do something for mobile users.
    $('.navbar-pc .dropdown .dropdown-toggle').attr("href", '#');
  }
  //往上滑
  $.apScrollTop({
    'onInit': function(evt) {
      // console.log('apScrollTop: init');
    }
  });


  $('[data-toggle="offcanvas"]').click(function() {
    $('.wrapper').toggleClass('toggled');
  });

  $(window).resize(function() {
    var menu_trigger = $('.hamburger'),
      overlay = $('.overlay'),
      closed = false;

    function menuResize() {
      WindowsWidth = $("body").width();
      // console.log(WindowsWidth);

      if (768 >= WindowsWidth) {
        menu_trigger.addClass("is-closed");
        closed = false;
        // title = 0;
      } else {
        overlay.css("display", "none");
        menu_trigger.removeClass("is-open");
        $('.wrapper').removeClass('toggled');
        $('[data-toggle="offcanvas"] .wrapper').toggleClass('toggled');
      }
    }

    menuResize();
  });


  // 選單動畫
  $('.sidebar-nav > .dropdown').on('show.bs.dropdown', function() {
    $(this).find('.dropdown-menu').first().stop(true, true).slideDown();
  });

  // Add slideUp animation to Bootstrap dropdown when collapsing.
  $('.sidebar-nav > .dropdown').on('hide.bs.dropdown', function() {
    $(this).find('.dropdown-menu').first().stop(true, true).slideUp();
  });

});


// 選單變色*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////
$(window).scroll(function() {
  var scroll = $(window).scrollTop();
  //console.log(scroll);
  if (scroll >= 50) {
    //console.log('a');
    $(".navbar-pc").addClass("change");
  } else {
    //console.log('a');
    $(".navbar-pc").removeClass("change");
  }
});

// 選單~子選單下拉滑動*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////
// 使用 _leftclass_fullList /  _leftclass_fullList_rightImg 選單須關閉
// scrollTop: target.offset().top - 80 // 80為nav的高度
// a.anchor(name="") 頂點css要設置如下
// a.anchor {
//   display: block;
//   position: relative;
//   top: -250px;
//   visibility: hidden;
// }


$(function() {
  $(".navbar .fastlink a[href*='#']:not([href='#'])").click(function() {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      if (target.length) {
        $('html,body').animate({
          scrollTop: target.offset().top
        }, 1000);
        return false;
      }
    }
  });
});


// 下拉選單修正版(電腦)*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////*/////////////////////////////////
$(document).ready(function() {

  $(".nav .dropdown").hover(function() {
    $(this).children('.dropdown-menu').addClass('menudown');
    $(this).children('.dropdown-menu').removeClass('menuup');
    $(this).children('.dropdown-menu').addClass('canhover');
    $(this).children('.dropdown-menu').removeClass('stophover');

  }, function() {


    var child = $(this).children('.dropdown-menu');
    if (child.mouseover() !== true || (this).mouseover() !== true) {
      $(this).children('.dropdown-menu').addClass('stophover');
      $(this).children('.dropdown-menu').addClass('menuup');
      $(this).children('.dropdown-menu').removeClass('menudown');
    }

  });

  // 手機板的子選單的子選單關閉滑動
  $('ul.dropdown-menu [data-toggle=dropdown]').on('click', function(event) {
    // Avoid following the href location when clicking
    event.preventDefault();
    // Avoid having the menu to close when clicking
    event.stopPropagation();
    // If a menu is already open we close it
    //$('ul.dropdown-menu [data-toggle=dropdown]').parent().removeClass('open');
    // opening the one you clicked on
    $(this).parent().addClass('open');

    var menu = $(this).parent().find("ul");
    var menupos = menu.offset();

    if ((menupos.left + menu.width()) + 30 > $(window).width()) {
      var newpos = -menu.width();
    } else {
      var newpos = $(this).parent().width();
    }
    // menu.css({ left:newpos });

  });

  /*****語言下拉選單****************/

  $(".mobile-languageBtn").click(function() {
    $box = $("#myNavbar");
    $body = $("body");
    $time = 300;
    $box.addClass('in');
    // reset height and revert to original if current and auto are equal
    //- $box.css('height', autoHeight).animate({
    //-     height: (autoHeight == minimumHeight ? minimumHeight : autoHeight)
    //- })
    if ($box.css('height') > '1px') {
      $box.animate({
        height: '0px'
      }, $time);
      setTimeout(function() {
        $box.removeClass('in');
        $body.css('overflow', 'auto');
      }, $time * 2);

    } else {
      $box.animate({
        height: '100%'
      }, $time);
      $body.css('overflow', 'hidden');
    }
  });

});
