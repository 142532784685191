// import Swiper , { Navigation } from 'swiper';
// Swiper.use([Navigation]);
// 單一一個
// new Swiper('.swiper-container', {

var swiperContainer = new Swiper(".swiper-about", {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    paginationClickable: false,
    autoplay:{
        delay:7000
    },
    speed:2000,

    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev',
    // },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    // breakpoints: {
    //     1024: {
    //         slidesPerView: 1,
    //         spaceBetween: 30
    //     },
    //     768: {
    //         slidesPerView: 1,
    //         spaceBetween: 30
    //     },
    //     480: {
    //         slidesPerView: 1,
    //         spaceBetween: 10
    //     }
    // }
});


var swiperservice0 = new Swiper(".swiper-service0", {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    paginationClickable: false,
    autoplay:{
        delay:7000
    },
    speed:2000,
  
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});

var swiperservice1 = new Swiper(".swiper-service1", {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 0,
    paginationClickable: false,
    autoplay:{
        delay:7000
    },
    speed:2000,
    
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});



var swiperquality = new Swiper(".swiper-quality", {
    loop: false,
    slidesPerView: 3,
    spaceBetween: 0,
    paginationClickable: false,
    // autoplay:{
    //     delay:7000
    // },
    speed:2000,
    
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true,
    // },
    breakpoints: {
        1024: {
            slidesPerView: 3,
            spaceBetween: 30
        },
        768: {
            slidesPerView: 2,
            spaceBetween: 30
        },
        480: {
            slidesPerView: 1,
            spaceBetween: 10
        }
    }
});